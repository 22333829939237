import React from 'react'
import MoveYourFamily from '../Home/MoveYourFamily/MoveYourFamily'
import Carrer from './Carrer/Carrer'
import TeamMembers from './TeamMembers/TeamMember'

const TeamSelby = () => {
  return (
    <>
      <TeamMembers />
      <Carrer />
      <MoveYourFamily />
    </>
  )
}

export default TeamSelby
