import { Link } from 'gatsby'
import React from 'react'
import Button from '../../Button/Button'

const Carrer = () => {
  return (
    <>
      <div className="py-[108px] bg-[#fff] sm:py-[50px]">
        <div className="container">
          <div className="text-center">
            <h2 className='text-sm-2xl font-[700] font-IbarraRealNova mb-[24px] sm:text-d-6lg sm:mb-[10px]'>Build a <span className='font-[500] font-Quicksand'>Career</span> With Us</h2>
           
            <p className='text-[#666666] text-d-3lg font-Quicksand font-[500] mb-[24px] sm:text-d-2lg'>
              Are you the type of realtor who puts the client first because you
              feel <i className='text-d-4lg font-[600] font-IbarraRealNova '>their</i> success is <i className='text-d-4lg font-[600] font-IbarraRealNova '>your</i> <br className='sm:hidden'/>
              success? If the answer is yes, you’re just the person we want on
              our real estate team.
            </p>

            <Link
              to="/sell-your-san-diego-home"
              className="xs:max-w-[48%] xs:w-full"
            >
              <Button
                type="solid"
                color="black"
                customStyle="px-[30px] xs:px-[0] xs:w-full xs:justify-center"
              >
                <p>Let’s connect</p>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Carrer
