import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import TeamSelby from "../component/TeamSelby/index";
const Team = () => {
  return (
      <LayoutWrapper
      title="San Diego Real Estate Agents | The Selby Team"
      desc="Meet The Selby Team, San Diego real estate agents who listen, are readily available and will work tirelessly for you. Call us today!"
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <TeamSelby />
    </LayoutWrapper>
  );
};

export default Team;
