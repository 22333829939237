import React from "react";
import { Link } from "gatsby";
import tm_1 from '../../../images/tm_1.png';
import tm_2 from '../../../images/tm_2.png';
import tm_3 from '../../../images/tm_3.png';
import tm_4 from '../../../images/tm_4.jpg';
import tm_5 from '../../../images/tm_6.png';
import tm_6 from '../../../images/tm_6.png';
import tm_7 from '../../../images/tm_7.png';

const TeamMembersData = [
  {
    profile: tm_1,
    name: "Joanie Selby",
    profassion: "TEAM LEADER / SALE AGENT",
    id: "joanie-selby",
  },
  {
    profile: tm_2,
    name: "John Selby",
    profassion: "TEAM LEADER / SALE AGENT",
    id: "john-selby",
  },
  {
    profile: tm_3,
    name: "Steve Melanese",
    profassion: "REALTOR",
    id: "steve-melanese",
  },
  {
    profile: tm_4,
    name: "Matthew Calvin",
    profassion: "REFERRAL AGENT",
    id: "matthew-calvin",
  },
  {
    profile: tm_7,
    name: "Cassandra Altmann",
    profassion: "REALTOR",
    id: "cassandra-altmann",
  },
 /* {
    profile: tm_5,
    name: "Jenna Selby",
    profassion: "MARKETING / PHOTOGRAPHER",
    id: "jenna-selby",
  }, */
  {
    profile: tm_5,
    name: "Ryan Hunter",
    profassion: "CLIENT CARE COORDINATOR",
    id: "ryan-hunter",
  },
];

const TeamMembers = () => {
  return (
    <>
      <div className="pt-[130px] pb-[89px] md:pt-[180px] sm:pt-[30px] sm:pb-[10px]">
        <div className="container">
          <div className="">
            <h1 className="font-IbarraRealNova font-[600] text-d-3xl pb-[24px] sm:text-d-6lg sm:pb-[10px]">
              Meet{" "}
              <span className="font-Quicksand font-[500] text-ms-4xl sm:text-d-5lg">
                THE
              </span>{" "}
              SELBY{" "}
              <span className="font-Quicksand font-[500] text-ms-4xl sm:text-d-5lg">
                TEAM
              </span>
            </h1>
            <p className="font-[500] text-[#666666] text-d-3lg font-Quicksand sm:text-d-2lg">
              Selby is about family—and we're ready to help yours move forward.
              Lean on our <br className="2xs:hidden" />
              friendly, experienced team for all your San Diego real estate
              needs.
            </p>
          </div>

          <div className="pt-[70px] sm:pt-[40px]">
            <div className="flex flex-wrap justify-between gap-y-[48px]">
              {TeamMembersData.map((value) => {
                return (
                  <div className="teamMember_card">
                    <Link to={`/san-diego-real-estate-agents/${value.id}`}>
                      <img src={value.profile} alt="" className="mb-[16px]" />
                    </Link>
                    <h3 className="font-IbarraRealNova font-[700] text-d-6lg mb-[4px] md:text-sm-m">
                      <Link to={`/san-diego-real-estate-agents/${value.id}`}>
                        {value.name}
                      </Link>
                    </h3>
                    <p className="text-sm-2lg font-[700] uppercase font-Quicksand text-[#B2B2B2]">
                      {value.profassion}
                    </p>
                  </div> 
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamMembers;
